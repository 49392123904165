body {
  margin: 0;
  font-size: 16px;
  font-family: 'Montserrat', Helvetica, sans-serif;
  color: #333;
}

a, a:visited {
  color: #333;
}

header {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 1;
  box-sizing: border-box;
  background-color: rgba(255,255,255,.95);
}

header h1 {
  margin: 0;
  padding-left: 15px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
}

header a {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 15px;
  text-decoration: none;
}

@media (min-width: 480px) {

  header h1 {
    font-size: 26px;
  }

  header a {
    font-size: 15px;
  }

}

header a.current {
  font-weight: 600;
}

header a.icon {
  font-size: 17px;
}

#images-container {
  position: relative;
  list-style-type: none;
  margin: 50px auto 0 auto;
  padding: 0;
}

#images-container li {
  position: absolute;
  cursor: pointer;
  transition: top 500ms ease 0s, bottom 500ms ease 0s, left 500ms ease 0s, right 500ms ease 0s;
}

#images-container .emphasis-block {
  display: none;
}

#images-container li:hover .emphasis-block {
  display: block;
  box-shadow: 0 5px 15px 0 rgba(71,82,93,.3);
  box-sizing: content-box;
  position: absolute;
  left: -15px;
  top: -15px;
  height: calc(100% + 30px);
  width: calc(100% + 30px);
  background: #fff;
}

.image-container {
  position: relative;
  overflow: hidden;
  background-color: rgb(250, 250, 250);
}

.image-container img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.image-container img.placeholder-image {
  filter: blur(0.75vw);
  transform: scale(1.05);
}

.image-container img.actual-image {
  display: none;
  animation: reveal .5s ease-out;
}

@keyframes reveal {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#images-container .title-block {
  position: absolute;
  display: flex;
  align-items: center;
  visibility: hidden;
}

#images-container .title-block h1 {
  margin: 0;
  font-size: .9em;
  font-weight: 400;
}

#full-image-container {
  display: none;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255,255,255,.95);
}

body.full-image #full-image-container {
  display: block;
}

body.full-image header {
  display: none;
}

#full-image {
  position: absolute;
  transition: top 500ms ease 0s, left 500ms ease 0s, height 500ms ease 0s, width 500ms ease 0s;
}

#full-image-caption {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0,0,0,.5);
  color: #ddd;
  padding: 5px;
  transform: translate(0, 100%);
  transition: transform 300ms ease 0s;
}

#full-image-caption.shown {
  transform: translate(0, 0);
}

#next-image, #prev-image {
  position: absolute;
  transition: top 500ms ease 0s, left 500ms ease 0s;
  cursor: pointer;
}

#full-image-details-container {
  position: absolute;
  display: flex;
  align-items: top;
  justify-content: center;
  width: 100%;
  left: 0;
  height: 100px;
  transform: translate(0, 100%);
  transition: transform 300ms ease 0s, top 500ms ease 0s, left 500ms ease 0s;
}

#full-image-details-container > .detail-container {
  width: 80px;
  height: 80px;
  margin: 0 5px;
  background-size: cover;
  background-position: center center;
  opacity: 0.7;
  cursor: pointer;
}

#full-image-details-container > .detail-container:first-child {
  margin-left: 20px;
}

#full-image-details-container > .detail-container:last-child {
  margin-right: 20px;
}

#full-image-details-container > .detail-container.current {
  opacity: 1;
}

#full-image-details-container.shown {
  transform: translate(0, 0);
}

#next-image path, #prev-image path {
  fill: #bbb;
}

#next-image:hover path, #prev-image:hover path {
  fill: #000;
}

#next-image {
  transform: translate(0, -50%);
}

#prev-image {
  transform: translate(0, -50%);
}

#close-full-image {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

#close-full-image path {
  fill: #666;
}

#close-full-image:hover path {
  fill: #000;
}

#curriculum-vitae {
  display: none;
  margin: 50px auto 0 auto;
  padding: 0;
}

#curriculum-vitae ul {
  padding: 0;
  margin: 20px;
  list-style-type: none;
}

#curriculum-vitae h3 {
  font-size: 14px;
  font-weight: 900;
  margin: 24px 0;
}

#curriculum-vitae li {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

body.curriculum-vitae #curriculum-vitae {
  display: flex;
}

body.curriculum-vitae #images-container {
  display: none;
}